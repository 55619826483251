import { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// importing all the themes

import Forgotpass from "themes/forgotpass-theme";
import Signintheme from "themes/signin-theme";

import Dashboardtheme from "themes/myprofile-theme";

import Pagenotfound from "Pages/404page";
import ViewEventViewDetails from "Pages/Profile/Events/ViewDetails";
import ManageChangeRoleUsers from "Pages/Profile/Pendinguser/ChangeRoleRequests";
import PitchViewDetails from "Pages/Profile/Pitches/ViewDetails";
import Posts from "Pages/Profile/Posts";
import PostViewDetails from "Pages/Profile/Posts/ViewDetails";
import Chnagepasstheme from "themes/chnagepass-theme";
import DashPosttheme from "themes/dashPost-theme";
import Dashaddcontesttheme from "themes/dashaddcontest-theme";
import Dashaddeventtheme from "themes/dashaddevent-theme";
import Dashaddnotifytheme from "themes/dashaddnotify-theme";
import Dashaddresourcestheme from "themes/dashaddresources-theme";
import Dashaddsubadmintheme from "themes/dashaddsubadmin-theme";
import Dashboardpagetheme from "themes/dashboard-theme";
import Dashchatroomtheme from "themes/dashchatroom-theme";
import Dashcmspagetheme from "themes/dashcmspage-theme";
import Dashcontesttheme from "themes/dashcontesttheme-theme";
import Dashcotestchalltheme from "themes/dashcotestchall-theme";
import Dashcustomerfeedbacktheme from "themes/dashcustomerfeedback-theme";
import Dashcustomersupporttheme from "themes/dashcustomersupport-theme";
import Dashdataanalyticstheme from "themes/dashdataanalytics-theme";
import Dasheventtheme from "themes/dashevent-theme";
import Dashmaincategorytheme from "themes/dashmaincategory-theme";
import Dashmanageusertheme from "themes/dashmanageuser-theme";
import Dashnotificaitiontheme from "themes/dashnotificaition-theme";
import Dashpedingchatroomtheme from "themes/dashpedingchatroom-theme";
import Dashpendingusertheme from "themes/dashpending-usertheme";
import Dashpendingeventstheme from "themes/dashpendingevents-theme";
import Dashpendinguserpreview from "themes/dashpendinguserpreview-theme";
import Dashpitchtheme from "themes/dashpitch-theme";
import Dashpitchestheme from "themes/dashpitches-theme";
import Dashreportusertheme from "themes/dashreportuser-theme";
import Dashresourcelibtheme from "themes/dashresourceliberary-theme";
import Dashskillinteresttheme from "themes/dashskillinterest-theme";
import Dashsubadmintheme from "themes/dashsubadmin-theme";
import UserViewDetails from "Pages/Profile/Pendinguser/Users/ViewDetails";
import AdminProfile from "Pages/AdminProfile";
import TermsconditionAU from "Pages/Profile/Cmspage/Cmscomponents/TermsconditionAU";
import AddFaqs from "Pages/Profile/Cmspage/Cmscomponents/faqs/AddFaqs";
import ChatViewDetails from "Pages/Profile/Pendinguser/Pendingchatroom/ViewDetails";
import AllNotification from "Pages/Profile/Notification/AllNotification";

class MyRouts extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            {!this.props?.isAuthenticated ? (
              <>
                <Route path="/" element={<Signintheme />} />
                <Route path="/forgot-password" element={<Forgotpass />} />
                <Route path="/change-password" element={<Chnagepasstheme />} />
                <Route path="*" element={<Pagenotfound />} />
              </>
            ) : (
              <>
                <Route element={<Dashboardtheme />}>
                  <Route path="/" element={<Dashboardpagetheme />} />
                  <Route path="/profile" element={<AdminProfile/>} />

                  <Route
                    path="/pending-users"
                    element={<Dashpendingusertheme />}
                  />
                  <Route
                    path="/user-view/:id"
                    element={<Dashpendinguserpreview />}
                  />
                  <Route
                    path="/change-role-request"
                    element={<ManageChangeRoleUsers />}
                  />
                  <Route
                    path="/pending-pitches"
                    element={<Dashpitchestheme />}
                  />
                  <Route
                    path="/contests-challenges"
                    element={<Dashcotestchalltheme />}
                  />
                  <Route
                    path="/pending-events"
                    element={<Dashpendingeventstheme />}
                  />
                  <Route
                    path="/pending-request-chatroom"
                    element={<Dashpedingchatroomtheme />}
                  />
                  <Route
                    path="/manage-users"
                    element={<Dashmanageusertheme />}
                  />

                  <Route path="/view-user/:id" element={<UserViewDetails />} />

                  <Route path="/category" element={<Dashmaincategorytheme />} />

                  <Route
                    path="/skill-and-interest"
                    element={<Dashskillinteresttheme />}
                  />

                  <Route path="/library" element={<Dashresourcelibtheme />} />
                  <Route
                    path="/add-resources"
                    element={<Dashaddresourcestheme />}
                  />

                  <Route path="/contests" element={<Dashcontesttheme />} />
                  <Route path="/pending-posts" element={<DashPosttheme />} />
                  <Route path="/view-post/:id" element={<PostViewDetails />} />

                  <Route
                    path="/add-contest"
                    element={<Dashaddcontesttheme />}
                  />

                  <Route path="/events" element={<Dasheventtheme />} />
                  <Route path="/pitches" element={<Dashpitchtheme />} />
                  <Route
                    path="/view-pitch/:id"
                    element={<PitchViewDetails />}
                  />

                  <Route path="/posts" element={<Posts />} />

                  <Route path="/add-events" element={<Dashaddeventtheme />} />
                  <Route
                    path="/edit-event/:id"
                    element={<Dashaddeventtheme />}
                  />
                  <Route
                    path="/view-event/:id"
                    element={<ViewEventViewDetails />}
                  />

                  <Route path="/chatroom" element={<Dashchatroomtheme />} />

                  <Route path="/sub-admin" element={<Dashsubadmintheme />} />
                  <Route
                    path="/add-subadmin"
                    element={<Dashaddsubadmintheme />}
                  />
                     <Route
                    path="/edit-subadmin/:id"
                    element={<Dashaddsubadmintheme />}
                  />

                  <Route
                    path="/notification"
                    element={<Dashnotificaitiontheme />}
                  />
                  <Route
                    path="/add-notification"
                    element={<Dashaddnotifytheme />}
                  />

                  <Route
                    path="/data-analytics"
                    element={<Dashdataanalyticstheme />}
                  />

                  <Route
                    path="/customer-support"
                    element={<Dashcustomersupporttheme />}
                  />
                  <Route
                    path="/customer-feedback"
                    element={<Dashcustomerfeedbacktheme />}
                  />

                  <Route
                    path="/report-user"
                    element={<Dashreportusertheme />}
                  />

                  <Route path="/cms" element={<Dashcmspagetheme />} />
                  <Route path="/add-cms" element={<TermsconditionAU />} />
                  <Route path="/edit-cms/:id" element={<TermsconditionAU />} />
                  <Route path="/add-faqs" element={<AddFaqs/>} />
                  <Route path="/edit-faqs/:id" element={<AddFaqs />} />
                  <Route path="/view-chat-room/:id" element={<ChatViewDetails />} />
                  <Route path="/all-notification" element={<AllNotification />} />




                </Route>

                <Route path="*" element={<Pagenotfound />} />
              </>
            )}
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
export default MyRouts;
