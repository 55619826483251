import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";

// images
import Logo from "../../assets/images/jamme_logo.svg";
import { z } from "zod";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { loginAdmin } from "store/actions";
import { zodResolver } from "@hookform/resolvers/zod";
import ButtonLoader from "Component/common/ButtonLoader";
import { useFirebase } from "../../firebase/firebase";


const schema = z.object({
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .email("Invalid email address"),
  password: z.string().min(1, { message: "Password is required" }),
});

const Signin = () => {
  const navigate = useNavigate();
  const [showpass, setshowpass] = useState(false);

  const eyetoggle = () => {
    setshowpass(!showpass);
  };

  const [rememberMe, setRememberMe] = useState(false);

  const loading = useSelector((s) => s.login.loading);

  const dispatch = useDispatch();
  // firebaseToken

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      email: localStorage.getItem("rememberedEmail") || "",
      password: localStorage.getItem("rememberedPassword") || "",
    },
  });
  const token = useFirebase();

  const firebasedata = token?.token;
  // console.log(firebasedata)
  const submitHandler = async (data) => {
    
    
    const callBack = () => {
      if (rememberMe) {
        localStorage.setItem("rememberedEmail", data.email);
        localStorage.setItem("rememberedPassword", data.password);
      }
      navigate("/");
    };
    dispatch(loginAdmin({...data,firebaseToken:firebasedata}, callBack));
  };

  return (
    <section id="login" className="authbg position-relative">
      <div className="container">
        <div className="wraaping_login">
          <div className="form_Side">
            <form
              id="loginform"
              className="spinner-content position-relative"
              onSubmit={handleSubmit(submitHandler)}
            >
              <div className="login_heading text-center">
                <img src={Logo} alt="" className="img-fluid" />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-group">
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter your Email"
                      {...register("email")}
                    />
                  </div>
                  {errors?.email && (
                    <p className="text-danger m-0 text-left  ">
                      {errors.email.message}
                    </p>
                  )}
                </div>

                <div className="col-lg-12">
                  <div className="input-group position-relative">
                    <input
                      type={showpass ? "text" : "password"}
                      name="password"
                      placeholder="Enter your password"
                      {...register("password")}
                    />

                    <Button
                      className="btn_eye"
                      type="button"
                      onClick={eyetoggle}
                    >
                      {showpass ? <>{eyeopen}</> : <>{eyeclose}</>}
                    </Button>
                  </div>
                  {errors?.password && (
                    <p className="text-danger m-0  text-left">
                      {errors.password.message}
                    </p>
                  )}
                </div>

                <div className="remember_me d-flex justify-content-between mt-3 mb-lg-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        onChange={(e) => {
                          setRememberMe(e.target.checked);
                        }}
                      />{" "}
                      Remember Me
                    </Label>
                  </FormGroup>
                  <div className="forgor-password">
                    <Link to="/forgot-password" className="colortextlink">
                      Forgot Password?
                    </Link>
                  </div>
                </div>

                <div className="col-lg-12">
                  <Button className="themebtn mt-3" type="submit">
                    {loading ? <ButtonLoader /> : "Sign In"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* 
      <div className="col-lg-12">
        <div className="reglink">
          <Link to="/signup" className="colortextlink">
            Don't have an account?
          </Link>
        </div>
      </div> */}
      </div>
    </section>
  );
};

export default Signin;

// svg

const eyeopen = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M12 9a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5c-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0a9.821 9.821 0 0 0-17.64 0"
    />
  </svg>
);

const eyeclose = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M2 5.27L3.28 4L20 20.72L18.73 22l-3.08-3.08c-1.15.38-2.37.58-3.65.58c-5 0-9.27-3.11-11-7.5c.69-1.76 1.79-3.31 3.19-4.54zM12 9a3 3 0 0 1 3 3a3 3 0 0 1-.17 1L11 9.17A3 3 0 0 1 12 9m0-4.5c5 0 9.27 3.11 11 7.5a11.8 11.8 0 0 1-4 5.19l-1.42-1.43A9.86 9.86 0 0 0 20.82 12A9.82 9.82 0 0 0 12 6.5c-1.09 0-2.16.18-3.16.5L7.3 5.47c1.44-.62 3.03-.97 4.7-.97M3.18 12A9.82 9.82 0 0 0 12 17.5c.69 0 1.37-.07 2-.21L11.72 15A3.064 3.064 0 0 1 9 12.28L5.6 8.87c-.99.85-1.82 1.91-2.42 3.13"
    />
  </svg>
);
