import Fade from "react-reveal";
import { Container, Form } from "reactstrap";

import "rsuite/dist/rsuite.min.css";

// images
import CustomPagination from "Component/common/CustomPagination";
import DatePickerField from "Component/common/DatePickerField";
import SearchFilter from "Component/common/SearchFilter";
import TableLayout from "Component/common/TableLayout";
import TableStatusOptionsAction from "Component/common/TableStatusOptionsAction";
import dataHandler from "hooks/dataHandler";
import { CHAT_ROOM_LIST, MANAGE_CHAT_ROOM_POSTS, MANAGE_CHAT_ROOMS, MANAGE_POSTS, POST_LIST } from "services/ApiCalls";
import TableProfile from "Component/common/TableLayout/TableProfile";
import { commonStatusOptions } from "utilities/const";
import moment from "moment";
import TableActions from "Component/common/TableActions";
import { truncateString } from "utilities/utilities";

const PendingPosts = () => {
  const {
    body,
    deleteHandler,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    statusChangeHandler,
    total,
    loader,
    setBody,
    data,
    refetch,
  } = dataHandler({
    api: CHAT_ROOM_LIST,
    dataToSet: (data) => data?.data,
    extraBody: {
      status: 0,
    
    },
  
  });

  const col = [
    {
      head: "Banner Image",
      accessor: "email",
      component: (item) => (
        <TableProfile image={item?.image} />
      ),
    },
    {
      head: "User Name",
      accessor: "",
      component: (item) => (
        <>
          {item?.userId?.fname} {item?.userId?.lname}
        </>
      ),
    },
 
    {
      head: "Room Name",
      accessor: "",
      component: (item) => (
        <>{(item?.name || "-")}</>
      ),
    },
 
    {
      head: "Description",
      accessor: "description",
      component: (item) => <>{ truncateString(item?.description,15)}</>,

    },
    {
      head: "Status",
      accessor: "accountType",
      component: (item, index) => (
        <TableStatusOptionsAction
          onChange={(userStatus, reason) => {
            statusChangeHandler(
              () =>
                MANAGE_CHAT_ROOMS(
                  reason
                    ? { ...reason, _id: item?._id, status: +userStatus }
                    : { _id: item?._id, status: +userStatus }
                ),
              index,
              "status",
              +userStatus,
              true
            );
          }}
          statusOptionArr={commonStatusOptions}
          value={item?.status}
          showRejectModel={true}
        />
      ),
    },
    {
      head: "Action",
      accessor: "",
      component: (item) => (
        <>
          <TableActions viewLink={`/view-chat-room/${item?._id}`} />
        </>
      ),
    },
  ];
  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <h3 className="fillter_head">Manage Chatroom Requests</h3>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 mt-4">
              <div className="fillter_area d-flex">
                <div className="fillter_by d-flex align-items-center gap-3">
                  <h5>Filter by :</h5>
                  <div className="search_form d-flex align-items-end gap-3">
                    <SearchFilter body={body} setBody={setBody} />

                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>

        <div className="user_tabbingset mt-5">
          <section className="tabbing_common">
            <div className="tab-frame user_frame">
              {/* <div className="clearfix">
          <input
            type="radio"
            name="tab"
            id="tab1"
            checked={state.tab == "tab1"}
            onClick={() => setState({ tab: "tab1" })}
          />
          <label for="tab1">Pending</label>

          <input
            type="radio"
            name="tab"
            id="tab2"
            checked={state.tab == "tab2"}
            onClick={() => setState({ tab: "tab2" })}
          />
          <label for="tab2">Decline</label>
        </div> */}

              {/* {state.tab == "tab1" && ( */}
              <div className="pending_user_content mt-4">
                <section className="Diagnosis_table com_table ">
                  <Container>
                  <TableLayout data={data} column={col} loader={loader} body={body} />
                  <div className="pagination_list d-flex align-items-center gap-3 justify-content-end py-3">
                      <CustomPagination
                        total={total}
                        body={body}
                        setBody={setBody}
                        pageChangeHandler={paginationHandler}
                      />
                    </div>
                  </Container>
                </section>
              </div>
              {/* )} */}

              {/* {state.tab == "tab2" && (
          <div className="pending_user_content mt-4">

            <div className="no_data text-center">
              <img src={Nodatagif} alt="" className="img-fluid" />
              <p>No Data Found!</p>
            </div>
          </div>
        )} */}
            </div>
          </section>
        </div>
      </section>
    </Fade>
  );
};

export default PendingPosts;
