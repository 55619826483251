import Axios from "./Axios";
import API_URL from "./URLS";

export const LOGIN_ADMIN = (data) => Axios.post(API_URL.LOGIN_ADMIN, data);
export const FORGET_PASSWORD_ADMIN = (data) =>
  Axios.post(API_URL.FORGET_PASSWORD_ADMIN, data);
export const RESET_PASSWORD = (data) =>
  Axios.post(API_URL.RESET_PASSWORD, data);
export const VERIFY_OTP = (data) => Axios.post(API_URL.VERIFY_OTP, data);

// upload file
export const ADMIN_PROFILE = () =>
  Axios.post(`${API_URL.GET_PROFILE}`);
export const DASHBOARD_COUNT= () =>
  Axios.get(`${API_URL.DASHBOARD_COUNT}`);

export const UPLOAD_FILE = (data) => Axios.post(API_URL.UPLOAD_FILE, data);

// Users

export const USERS_LIST = (data) =>
  Axios.post(`${API_URL.GET_USER_LIST}`, data);

export const MANAGE_USER = (data) => Axios.post(`${API_URL.MANAGE_USER}`, data);
export const USER_DETAILS = (data) =>
  Axios.get(`${API_URL.USER_DETAILS}${data}`);
// EVENTS

export const EVENT_LIST = (data) => Axios.post(`${API_URL.EVENT_LIST}`, data);
export const MANAGE_EVENTS = (data) =>
  Axios.post(`${API_URL.MANAGE_EVENTS}`, data);
export const EVENT_DETAILS = (data) =>
  Axios.get(`${API_URL.EVENT_DETAILS}${data}`);
export const ADD_EDIT_EVENT = (data) =>
  Axios.post(`${API_URL.ADD_EDIT_EVENT}`, data);
//Pitch
export const PITCHES_LIST = (data) =>
  Axios.post(`${API_URL.GET_PITCH_LIST}`, data);
export const MANAGE_PITCH = (data) =>
  Axios.post(`${API_URL.MANAGE_PITCH}`, data);
export const PITCH_DETAILS = (data) =>
  Axios.get(`${API_URL.PITCH_DETAILS}${data}`);

// POSTS

export const POST_LIST = (data) => Axios.post(`${API_URL.POST_LIST}`, data);
export const MANAGE_POSTS = (data) =>
  Axios.post(`${API_URL.MANAGE_POSTS}`, data);
export const POST_DETAILS = (data) =>
  Axios.get(`${API_URL.POST_DETAILS}/${data}`);

// Customer Support and feedback
export const SUPPORT_LIST = (data) =>
  Axios.post(`${API_URL.SUPPORT_LIST}`, data);
export const FEEDBACK_LIST = (data) =>
  Axios.post(`${API_URL.FEEDBACK_LIST}`, data);
export const MANAGE_SUPPORT = (data) =>
  Axios.post(`${API_URL.MANAGE_SUPPORT}`, data);
export const MANAGE_FEEDBACK = (data) =>
  Axios.post(`${API_URL.MANAGE_FEEDBACK}`, data);

// Sub-Admin

export const ADD_SUB_ADMIN = (data) =>
  Axios.post(`${API_URL.ADD_SUB_ADMIN}`, data);
export const GET_SUB_ADMIN_LIST = (data) => Axios.post(`${API_URL.GET_SUB_ADMIN_LIST}`, data);
export const MANAGE_SUB_ADMIN = (data) =>
  Axios.post(`${API_URL.MANAGE_SUBADMIN}`, data);
export const SUB_ADMIN_DETAILS = (data) =>
  Axios.get(`${API_URL.SUB_ADMIN_DETAILS}${data}`);

// CMS Pages-
export const ADD_TERMS_CONDITION = (data) =>
  Axios.post(`${API_URL.ADD_TERMS_CONDITION}`, data);

export const CMS_LIST = (data) => Axios.post(`${API_URL.CMS_LIST}`, data);
export const CMS_DETAILS = (data) =>
  Axios.get(`${API_URL.CMS_LIST_DETAIL}${data}`);

// FAQs Pages-


// Updated function to add or edit FAQ
export const ADD_FAQ = (data) =>
  Axios.post(`${API_URL.ADD_FAQ}`, data);

// Updated function to get the list of FAQs
export const FAQ_LIST = (data) =>
  Axios.post(`${API_URL.FAQ_LIST}`, data);

// Updated function to get details of a specific FAQ
export const FAQ_DETAILS = (id) =>
  Axios.get(`${API_URL.FAQ_DETAIL}${id}`);

// chat room


export const CHAT_ROOM_LIST = (data) => Axios.post(`${API_URL.CHAT_ROOM_LIST}`, data);
export const MANAGE_CHAT_ROOMS = (data) =>
  Axios.post(`${API_URL.MANAGE_CHAT_ROOMS}`, data);
export const CHAT_DETAILS = (data) =>
  Axios.get(`${API_URL.CHAT_DETAILS}${data}`);

// Function to add/edit push notifications
export const ADD_EDIT_PUSH_NOTIFICATION = (data) =>
  Axios.post(`${API_URL.ADD_EDIT_PUSH_NOTIFICATION}`, data);

// Function to get the list of push notifications
export const PUSH_NOTIFICATION_LIST = (data) =>
  Axios.post(`${API_URL.PUSH_NOTIFICATION_LIST}`, data);
export const NOTIFICATION_LIST = (data) =>
  Axios.post(`${API_URL.LIST_NOTIFICATION}`,data);
export const MANAGE_NOTIFICATION = (data) =>
  Axios.post(`${API_URL.MANAGE_NOTIFICATION}`,data);
export const GET_ANALTICS_DATA = (data) => Axios.post(`${API_URL.ANALYTICS_DATA}`, data);

