import { CMS_LIST, MANAGE_FEEDBACK, MANAGE_PITCH, SUPPORT_LIST } from "./ApiCalls";

export default {
  API_URL: "https://api.jamme3na.org/v1/",
  // ADMIN
  LOGIN_ADMIN: "/admin/login",
  FORGET_PASSWORD_ADMIN: "/admin/forgotPassword",
  VERIFY_OTP: "/admin/verifyOTP",
  RESET_PASSWORD: "/admin/resetPassword",
  // upload file
  GET_PROFILE: "/admin/accessTokenLogin",
  DASHBOARD_COUNT:"/admin/admindashboard",

  UPLOAD_FILE: "/openApis/uploadFile",
  // Users

  GET_USER_LIST: "/admin/listUsers",
  MANAGE_USER: "/admin/manageUsers",
  USER_DETAILS: "/admin/userDetails/",
  // events
  EVENT_LIST: "/admin/events/listEvents",
  MANAGE_EVENTS: "/admin/events/manageEvents",
  EVENT_DETAILS: "/admin/events/eventsDetails/",
  // Pitches
  GET_PITCH_LIST: "/admin/pitchs/listPitchs",
  MANAGE_PITCH: "/admin/pitchs/managePitchs",
  PITCH_DETAILS: "/admin/pitchs/pitchsDetails/",
  // events
  POST_LIST: "/admin/posts/listPosts",
  MANAGE_POSTS: "/admin/posts/managePosts",
  POST_DETAILS: "/admin/posts/postsDetails/",
  ADD_EDIT_EVENT: "/admin/events/addEditEvents",
  //customer support and feedback
  SUPPORT_LIST: "/admin/listSupport",
  FEEDBACK_LIST: "/admin/listFeedback",
  MANAGE_SUPPORT: "/admin/manageSupport",
  MANAGE_FEEDBACK: "/admin/manageFeedback",

 // Sub-admin
 ADD_SUB_ADMIN:"/admin/addEditSubAdmins",
 GET_SUB_ADMIN_LIST: "/admin/listSubAdmins",
 MANAGE_SUBADMIN: "/admin/manageSubAdmins",
 SUB_ADMIN_DETAILS: "/admin/subAdminDetails/", 

 //CMS PAGEs -

 ADD_TERMS_CONDITION:"/admin/content/addEditContent",
 CMS_LIST:"/admin/content/listContents",
 CMS_LIST_DETAIL:"/admin/content/contentDetails/",


 //FQS PAGEs -

 ADD_FAQ: "/admin/faq/addEditFaq", // Updated for adding/editing FAQs
 FAQ_LIST: "/admin/faq/listFaqs", // Updated to list FAQs
 FAQ_DETAIL: "/admin/faq/faqDetails/", // Updated to get FAQ details

 CHAT_ROOM_LIST: "/admin/chat/listChatRooms",
 MANAGE_CHAT_ROOMS: "/admin/chat/manageChats",
 CHAT_DETAILS: "/admin/chat/chatRoomDetails/",

 //push notification

  PUSH_NOTIFICATION_LIST: "/admin/listPushNotifications", // Updated to list push notifications
  ADD_EDIT_PUSH_NOTIFICATION: "/admin/addEditPushNotification",
  LIST_NOTIFICATION:"/admin/listNotifications",
  MANAGE_NOTIFICATION:"/admin/readAdminNotifications",

  ANALYTICS_DATA:"/admin/listDataAndAnalytics",
};
