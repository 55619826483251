import Fade from "react-reveal";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form } from "reactstrap";

import "rsuite/dist/rsuite.min.css";

// images
import CustomPagination from "Component/common/CustomPagination";
import DatePickerField from "Component/common/DatePickerField";
import SearchFilter from "Component/common/SearchFilter";
import TableActions from "Component/common/TableActions";
import TableLayout from "Component/common/TableLayout";
import TableProfile from "Component/common/TableLayout/TableProfile";
import dataHandler from "hooks/dataHandler";
import moment from "moment";
import {
  EVENT_LIST,
  GET_SUB_ADMIN_LIST,
  MANAGE_SUB_ADMIN,
} from "services/ApiCalls";
import Plussvg from "../../../../assets/svg/plussvg.svg";
import { truncateString } from "utilities/utilities";
import TableStatusOptionsAction from "Component/common/TableStatusOptionsAction";

const substable = () => {
  const navigate = useNavigate();

  const {
    body,
    deleteHandler,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    statusChangeHandler,
    total,
    loader,
    setBody,
    data,
    refetch,
  } = dataHandler({
    api: GET_SUB_ADMIN_LIST,
    dataToSet: (data) => data?.data,
  });

  const col = [
    {
      head: "User Name",
      accessor: "name",
    },
    {
      head: "Email",
      accessor: "email",
    },

    {
      head: "Status",
      accessor: "accountType",
      component: (item, index) => (
        <TableStatusOptionsAction
          onChange={(userStatus, reason) => {
            statusChangeHandler(
              () =>
                MANAGE_SUB_ADMIN({
                  _id: item?._id,
                  isDeleted: userStatus == 0 ? true : false,
                  isBlocked: userStatus == 1 ? true : false,
                }),
              index,
              "status",
              +userStatus,
              true
            );
          }}
          statusOptionArr={
            item?.isBlocked == false
              ? [
                  {
                    value: 4,
                    label: "Select",
                  },
                  {
                    value: 0,
                    label: "Delete",
                  },

                  {
                    value: 1,
                    label: "Block",
                  },
                ]
              : [
                  {
                    value: 4,
                    label: "Select",
                  },
                  {
                    value: 0,
                    label: "Delete",
                  },

                  {
                    value: 2,
                    label: "Unblock",
                  },
                ]
          }
          value={item?.isDeleted == true ? 0 : item?.isBlocked == true ? 1 : 4}
        />
      ),
    },
    {
      head: "Action",
      accessor: "",
      component: (item) => (
        <>
          <TableActions
            editLink={`/edit-subadmin/${item?._id}`}
            // viewLink={`/view-event/${item?._id}`}
          />
        </>
      ),
    },
  ];

  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h3 className="fillter_head">Manage Subadmin</h3>
            </div>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 justify-content-between mt-4">
              <div className="fillter_area d-flex">
                <div className="fillter_by d-flex align-items-end gap-3">
                  <h5>Filter by :</h5>
                  <div className="search_form d-flex align-items-end gap-3">
                    <SearchFilter body={body} setBody={setBody} />
                  </div>
                </div>
              </div>

              <div className="export_btn d-flex align-items-center  justify-content-end gap-3">
                <Button
                  className="plussvg"
                  onClick={() => navigate("/add-subadmin")}
                >
                  <img src={Plussvg} alt="" className="img-fluid" />
                </Button>
              </div>
            </div>
          </div>
        </Form>

        <div className="manageuser_tabbingset mt-5">
          <section className="tabbing_common">
            <div className="tab-frame user_frame">
              <div className="manageuser_tabbingset mt-4">
                <section className="Diagnosis_table com_table ">
                  <Container>
                    <TableLayout
                      data={data}
                      column={col}
                      loader={loader}
                      body={body}
                    />
                    <div className="pagination_list d-flex align-items-center gap-3 justify-content-end py-3">
                      <CustomPagination
                        total={total}
                        body={body}
                        setBody={setBody}
                        pageChangeHandler={paginationHandler}
                      />
                    </div>
                  </Container>
                </section>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Fade>
  );
};

export default substable;
