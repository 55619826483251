import React, { useEffect, useState, createContext, useContext } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyBqYeUtFz3D_wX9Fbys6oby8WXSwbTAc7I",
  authDomain: "jamm3na-new.firebaseapp.com",
  projectId: "jamm3na-new",
  storageBucket: "jamm3na-new.appspot.com",
  messagingSenderId: "929332572314",
  appId: "1:929332572314:web:e433f7c16edd2577356f23",
  measurementId: "G-3RTW8C1Q49",
};

// Firebase Initialization
const firebaseApp = initializeApp(firebaseConfig);

// Custom hook to use Firebase messaging
const useFirebaseMessaging = () => {
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState(null);


  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );

          const messaging = getMessaging(firebaseApp);

          // Request for FCM token
          getToken(messaging, {
            vapidKey:
              "BMumvtvOW1VKeRu1Y-nh2gl8Bg3XdiSreJ3SjXO9JbgqV8rSrBIg2d9KnX8GJ_jl4jtq-m23Bru6-0sy7Z7qjV4",
          })
            .then((currentToken) => {
              if (currentToken) {
                // console.log("FCM Token:", currentToken);
                setToken(currentToken);
              } else {
                console.log(
                  "No registration token available. Request permission to generate one."
                );
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token: ", err);
            });

          // Listen for foreground messages
          onMessage(messaging, (payload) => {
            // console.log("Message received in foreground: ", payload);
            setMessage(payload);

            // Show notification in the foreground
            if (Notification.permission === "granted") {
              const { title, body } = payload.notification;
              new Notification(title, { body });
            }
          });
        })
        .catch((err) => {
          console.log("Service Worker registration failed: ", err);
        });
    }
  }, []);

  return { token, message };
};

// Context to provide Firebase messaging data
export const FirebaseContext = createContext({ token: "", message: "" });

export const FirebaseProvider = ({ children }) => {
  const firebaseMessaging = useFirebaseMessaging();
  return (
    <FirebaseContext.Provider value={firebaseMessaging}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => {
  return useContext(FirebaseContext);
};
