import React, { useEffect, useState } from "react";
import { Form, Button, Col, Row, Container } from "react-bootstrap";

import dummyImg from "../../assets/images/user.webp";
import { ADMIN_PROFILE } from "services/ApiCalls";
import { catchAsync, checkResponse } from "utilities/utilities";
import { FaCamera } from "react-icons/fa";

const AdminProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });

  const getData = catchAsync(async () => {
    const res = await ADMIN_PROFILE();
    checkResponse({
      res,
      setData: (data) => {
        console.log(data);
        setFormData({
          name: data?.name,
          email: data?.email,
          phone: data?.phone ? data?.phone : "",
          address: data?.address ? data?.address : "",
        });
        // setData(dataToSet);
      },
    });
  });

  useEffect(() => {
    getData();
  }, []);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container>
      <div className="form_Side profile_set">
        <form
          id="loginform"
          className="spinn-content forgot_password position-relative"
          // onSubmit={handleSubmit(submitHandler)}
        >
          <div className="profile-picture-container  text-center login_heading">
            <div className="profile-picture-wrapper ">
              <img
                src={dummyImg}
                alt="Profile"
                className="img-fluid profile-picture"
              />
              {isEditing && (
                <div className="overlay">
                  <label htmlFor="fileInput">
                    <FaCamera className="camera-icon" />
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="profile-info-card">
            <h4>Profile Information</h4>
            {!isEditing ? (
              <div className="profile-details ">
                <p style={{ color: "white" }}>Name: {formData.name}</p>
                <p style={{ color: "white" }}>Email: {formData.email}</p>
                {/* <p style={{ color: "white" }}>Phone: {formData.phone}</p>
                <p style={{ color: "white" }}>Address: {formData.address}</p> */}
                {/* <Button
                  onClick={() => setIsEditing(true)}
                  className="export_csv w-100"
                >
                  Edit Profile
                </Button> */}
              </div>
            ) : (
              <div className="author_form">
                <Row>
                  <Col>
                    <Form.Group controlId="formName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formPhone">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formAddress">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  className="export_csv w-100 mt-4"
                  onClick={() => setIsEditing(false)}
                >
                  Save Changes
                </Button>
              </div>
            )}
          </div>
        </form>
      </div>
    </Container>
  );
};

export default AdminProfile;
