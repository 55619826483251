import { toast } from "react-toastify";
import { UPLOAD_FILE } from "../services/ApiCalls";
import { catchAsync } from "./utilities";

const allowedExtensions = ["svg", "jpg", "png", "jpeg"];

export default catchAsync(async (files) => {
  const formData = new FormData();
console.log(files , "Files")
  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    const fileType = file?.type?.split("/")[1]; // Get the file extension
    if (!allowedExtensions.includes(fileType)) {
      toast.dismiss();
      toast.error(
        "Unsupported file type. Only SVG, JPG, PNG, and JPEG files are allowed."
      );
      return;
    }
    formData.append("files", files[index]);
  }

  const res = await UPLOAD_FILE(formData);
  if (res?.status === 200) {
    return res?.data?.files;
  } else {
    toast.dismiss();
    toast.error(res?.data?.message || res?.response?.data?.message);
    return false;
  }
});
