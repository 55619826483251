import React from "react";
import "./App.css";
import "../src/assets/css/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MyRouts from "./Routers/routes";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";

function App() {
  const isAuthenticated = useSelector((s) => s?.login?.token);
  // console.log(isAuthenticated)
  return (
    <div className="App">
      <MyRouts isAuthenticated={isAuthenticated} />
    </div>
  );
}

export default App;
